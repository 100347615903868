//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, inject } from '@vue/composition-api';
export default defineComponent({
  name: 'VfCheckbox',
  model: {
    prop: 'selected',
    event: 'change',
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Boolean],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    selected: {
      type: [Array, Boolean],
      default: () => [],
    },
  },
  setup() {
    // TODO: GLOBAL15-61059 remove after core redesign
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    return {
      isCoreRedesignEnabled,
    };
  },
  data() {
    return {
      uniqueId: null,
    };
  },
  computed: {
    isChecked() {
      if (typeof this.selected === 'boolean') {
        return this.selected;
      } else {
        return this.selected.includes(this.value);
      }
    },
    errorMessageId() {
      return this.name && `${this.name}__error-message`;
    },
  },
  mounted() {
    this.uniqueId = `${this.name}-${this._uid}`;
  },
  methods: {
    inputHandler() {
      if (typeof this.selected === 'boolean') {
        this.$emit('change', !this.selected);
      } else {
        let selected = [...this.selected];
        if (selected.includes(this.value)) {
          selected = selected.filter((value) => value !== this.value);
        } else {
          selected.push(this.value);
        }
        this.$emit('change', selected);
      }
    },
  },
});
