













































import { computed, defineComponent } from '@vue/composition-api';
import { useRootInstance } from '../../../theme/shared/useRootInstance';
import { clickOutside } from '@vf/shared/src/utils/directives';

export default defineComponent({
  name: 'VfQuickShopTileToggle',
  directives: { clickOutside },
  props: {
    title: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    open: {
      type: Boolean,
      default: false,
    },
    regularPrice: {
      type: String,
      default: '',
    },
    specialPrice: {
      type: String,
      default: '',
    },
    priceRange: {
      type: Object,
      default: () => ({}),
    },
    displayPriceRange: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      default: '',
    },
    searchState: {
      type: Object,
      default: null,
    },
    variantSelected: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggle-quickshop'],
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const handleToggleQuickShopOptions = (event): void => {
      if ([' ', 'Enter', 'Spacebar'].includes(event.key)) {
        // "Spacebar" for IE11 support
        // Prevent the default action to stop scrolling when space is pressed
        event.preventDefault();
        toggleQuickShopOptions();
      }
    };
    const priceRangeFormatted = computed(() => ({
      lowest: props.priceRange?.sale?.minPrice,
      isLowestPriceDiscounted:
        props.priceRange?.sale?.minPrice < props.priceRange?.base?.minPrice,
      highest: props.priceRange?.sale?.maxPrice,
      isHighestPriceDiscounted:
        props.priceRange?.sale?.maxPrice < props.priceRange?.base?.maxPrice,
      currency: props.currency,
    }));

    const showAsPriceRange = computed(() => {
      const rangeExists =
        props.displayPriceRange &&
        props.priceRange?.sale?.maxPrice - props.priceRange?.sale?.minPrice > 0;
      const productSelected = props.variantSelected;
      return (rangeExists && !productSelected) || (rangeExists && !props.open);
    });

    const theme = root.$themeConfig.productPrice;

    const toggleQuickShopOptions = (): void => {
      emit('toggle-quickshop', !props.open);
    };

    const closeQuickShopOptions = (): void => {
      if (!props.open) return;
      emit('toggle-quickshop', false);
    };

    const navigateWithState = (e): void => {
      emit('cache-plp', e);

      window.history.replaceState(
        {
          ...(window.history.state || {}),
          ...props.searchState,
        },
        ''
      );
      root.$router.push(props.link);
    };

    return {
      handleToggleQuickShopOptions,
      toggleQuickShopOptions,
      closeQuickShopOptions,
      navigateWithState,
      priceRangeFormatted,
      showAsPriceRange,
      theme,
    };
  },
});
